/**
 * Litter overlay
 */
export const LITTER_ID = 'litter_id';

/**
 * Puppy overlay
 */
export const PUPPY_ID = 'puppy_id';

/**
 * Puppy overlay
 */
export const BREED_ID = 'breed_id';

/**
 * Pagination
 */
export const PAGE = 'p';

/**
 * Breed description overlay
 */
export const BREED_DESCRIPTION_OPEN = 'breed_description_open';

/**
 * Results filtering
 */
export const FILTER_POSTAL_CODE = 'postal';
export const FILTER_LATITUDE = 'lat';
export const FILTER_LONGITUDE = 'long';
export const FILTER_LOCATION_NAME = 'locName';
export const FILTER_DISTANCE_IN_MILES = 'distance';
export const FILTER_AVAILABILITY = 'availability';
export const FILTER_DELIVERY_METHODS = 'delivery';
export const FILTER_SIZE = 'size';
export const FILTER_COLOR = 'color';
export const FILTER_COAT = 'coat_type';
export const FILTER_TAIL = 'tail_type';
export const FILTER_GENERATION = 'generation';
export const FILTER_GENDER = 'gender';
export const FILTER_PRICE = 'price_tier';
export const FILTER_HEALTH_TIER_LEVELS = 'health_tier_levels';
export const FILTER_AGE = 'age';
export const RESULTS_TYPE = 'results_type';
export const FILTERS_MODAL = 'filters';

/**
 * Results sorting
 */
export const SORT = 'sort';

// Add this parameter to make sure results page does not redirect with ip geolocation
export const LOCATION_REDIRECT = 'location_redirect';

/**
 * Photo albums
 */
export const PHOTO_ALBUM_TYPE = 'album';
export const IMAGE_ID = 'image_id';
export const PHOTO_COLLECTION_PATH = 'photo_collection_path';

/**
 * Adopter Application
 */
export const ADOPTER_APPLICATION_ID = 'adopter_app_id';
export const ADOPTER_APPLICATION_ACTION = 'adopter_app_action';
// Part of v2 application decline flow
export const CONVERSATION_ARCHIVED = 'conversation_archived';

/**
 * Conversations
 */
export const CONVERSATION_ID = 'conversation_id';
export const TAB_INDEX = 'tab_index';
/**
 * Shelter navigation
 */
export const SHELTERS_LOCATE = 'locate';
export const SHELTER_ID = 'shelter_id';

/**
 * Shelter dog (or I guess any dog, really)
 */
export const DOG_ID = 'dog_id';

/**
 * Breeder onboarding quiz
 */
export const BREEDER_ONBOARDING_SUCCESS = 'onboarded';

/**
 * Breeder Signup
 */
export const PARTNERSHIP = 'partnership';

/**
 * Family (waitlist) sidebar
 */
export const FAMILY_ID = 'family_id';

/**
 * Payment request modals
 */
export const PAYMENT_REQUEST_ID = 'payment_request_id'; // external_id
export const PAYMENT_REQUEST_ACTION = 'payment_request_action';
export const PAYMENT_REQUEST_BUYER_EMAIL = 'email'; // TODO: very generic param name!
export const PAYMENT_REQUEST_AMOUNT = 'amount';
export const PAYMENT_REQUEST_CATEGORY = 'category';
export const PAYMENT_CHECKOUT_SESSION_ID = 'checkout_session_id';

/**
 * Payment settings page success modal
 */
export const PAYPAL_CONFIRMED_PARAM = 'paypal_confirmed';

/**
 * Payment settings page open payout modal
 */
export const PAYOUT_MODAL_OPEN = 'payout_modal_open';

/**
 * International Breeder Signup modal
 */
export const INTERNATIONAL_BREEDER_SIGNUP = 'international_breeder_signup';

/**
 * Breeder Email Confirmation Modal
 */
export const EMAIL_CONFIRMED = 'email_confirmed';

/**
 * Breeder Profile
 */
export const TESTIMONIAL_MODAL_PAGE = 'verified_owners_p';
export const TESTIMONIAL_MODAL_ID = 'testimonial_id';
export const BREEDER_PROFILE_ID = 'breeder_profile_id';

/**
 * Breeder Post
 */
export const BREEDER_POST_ID = 'breeder_post_id';
export const SLIDE_IDX = 'slide_idx';

/**
 * Health test levels overlay
 */
export const HEALTH_TESTING_MODAL_OPEN = 'health_testing_levels_open';

/**
 * Litter filtering on breeder dashboard waitlist page
 */
export const LITTER_IDS = 'litter_ids';
export const BREED_IDS = 'breed_ids';

/**
 * initial Inquiry Recommendations
 */
export const CITY_SLUG = 'city_slug';

// General Analytics Props
export const ANALYTICS = 'a';
export const UTM_MEDIUM = 'utm_medium';

/**
 * Buyer application request modal
 */
export const BUYER_APPLICATION_REQUEST_MODAL_OPEN =
  'buyer_application_request_open';

/**
 * Buyer application withdrawal modal
 */
export const BUYER_APPLICATION_WITHDRAWAL_MODAL_OPEN =
  'buyer_application_withdrawal_open';

/**
 * Breeder share insurance info modal
 */
export const SHARE_INSURANCE_INFO = 'share_insurance_info';

/**
 * Inbox
 */
export const INBOX_SEARCH_BY_NAME = 'inbox_search_by_name';

export const UNREAD = 'unread';

/**
 * Breeder send testimonial request
 */
export const SEND_TESTIMONIAL_REQUEST = 'send_testimonial_request';

/**
 * Admin testimonial id
 */
export const IS_ADMIN_EDITING = 'is_admin_editing';

/**
 * Contact Form
 */
export const CONTACT_TYPE = 'contact';
export const CONTACT_BREEDER_ID = 'contactBID';
export const CONTACT_DOG_ID = 'contactDID';
